<template>
  <!-- <div class="sideNav">
    <div
      v-for="tab in tabs"
      :key="tab"
      @click="selected = tab"
      :class="['tab-btn navItem clickable', { activeRound: selected === tab }]"
    >
      {{ tab }}
    </div>
  </div> -->
  <div class="taskMainDetails">
    <div class="leftDetails">
      <div>
        <h4>Important details</h4>
        <div class="description">
          {{ task.objectives }}
        </div>
        <div class="smallTitle">Description</div>
        <div class="description">
          {{ task.description }}
        </div>
        <div class="smallTitle">Assignees</div>
        <div class="members">
          <AssigneesProfiles :value="task.assignees" />
        </div>
      </div>
      <div>
        <EditTasks :task="task" />
      </div>
    </div>
    <div class="viewSubTasks rightDetails">
      <Subtasks
        class="tab"
        :subtasks="task.subtasks || []"
        @update="updateSubtasks"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import Subtasks from "@/views/tasks/Subtasks.vue";
import EditTasks from "@/components/projects/EditTasks.vue";
import AssigneesProfiles from "@/components/commons/AssigneesProfiles.vue";

export default {
  name: "TaskDescription",
  components: {
    Subtasks,
    EditTasks,
    AssigneesProfiles
  },
  emits: ["update-subtasks"],
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const selected = ref("Subtasks");

    const updateSubtasks = subtasks => {
      emit("update-subtasks", subtasks);
    };

    return {
      selected,
      updateSubtasks
    };
  }
};
</script>
