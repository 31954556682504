<template>
  <div>
    <BaseButton
      v-if="!showForm"
      modifiers="secondary textonly"
      @click="showForm = true"
    >
      Update details
    </BaseButton>
    <div v-if="showForm" class="edit-task">
      <div class="form-wrapper">
        <CloseModal @close="showForm = false" />

        <form v-if="showForm" @submit.prevent="updateTaskDetails">
          <h4>Update this task's details</h4>
          <input type="text" v-model="form.name" />
          <input type="text" v-model="form.description" />
          <input
            placeholder="Estimated time (in hours)"
            v-model="form.estimatedTime"
          />
          <select v-model="form.priority" placeholder="project.priority">
            <option value="urgent">Urgent</option>
            <option value="high">High</option>
            <option value="medium">Medium</option>
            <option value="low">Low</option>
          </select>
          <select v-model="form.assignees" multiple>
            <option
              v-for="user in companyUsers"
              :value="user.userId"
              :key="user.userId"
            >
              {{ user.firstName }} {{ user.lastName }}
            </option>
          </select>
          <BaseButton
            aria-label="create-project"
            modifiers="secondary"
            @click="closeOnSave = false"
          >
            Update now
          </BaseButton>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import { useRoute } from "vue-router";

import useDocument from "@/composables/useDocument";
import getCollection from "@/composables/getCollection";
import useCompanies from "@/composables/useCompanies";

import CloseModal from "@/components/designsystem/CloseModal";
import BaseButton from "@/components/designsystem/BaseButton";

export default {
  components: {
    CloseModal,
    BaseButton
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const route = useRoute();
    const showForm = ref(false);

    const { updateDoc } = useDocument(
      `companies/${route.params.companyId}/tasks`,
      props.task.id
    );

    const { company } = useCompanies();
    const { documents: companyUsers } = getCollection("users", [
      "userId",
      "in",
      company.value.members
    ]);

    const form = reactive({
      name: props.task.name,
      description: props.task.description,
      estimatedTime: props.task.estimatedTime,
      priority: props.task.priority,
      assignees: props.task.assignees
    });

    const updateTaskDetails = async () => {
      await updateDoc({
        name: form.name,
        description: form.description,
        estimatedTime: form.estimatedTime,
        priority: form.priority,
        assignees: form.assignees
      });
      showForm.value = false;
    };

    return {
      showForm,
      form,
      companyUsers,
      updateTaskDetails
    };
  }
};
</script>

<style lang="scss" scoped>
.edit-task {
  position: absolute;
  width: 100%;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  display: flex;
  z-index: 10;
}
.form-wrapper {
  background: white;
  border-radius: 9px;
  padding: 48px;
  position: relative;
  width: 100%;
}
.closeModal {
  right: 24px;
  top: 24px;
}
h4 {
  margin-bottom: 24px;
}
form {
  width: 100%;
  /* max-width: 100%; */
  text-align: left;
}
input,
select {
  margin-bottom: 12px;
}
.smallUpdate {
  background: none;
  color: $base-color;
  margin-top: 32px;
  padding: 0 12px;
}
.secondary {
  border: 2px solid $base-color;
  color: $base-color;
  margin-top: 32px;
}
</style>
