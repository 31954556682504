<template>
  <div class="">
    <div class="contents">
      <h4>List of things to do</h4>
      <div class="taskRow">
        <div class="checklist">
          <div
            class="checklistItem"
            v-for="(subtask, index) in subtasks"
            :key="index"
          >
            <div class="super" @click="toggleComplete(index)">
              <div v-if="subtask.complete" class="subtaskDef">
                <div class="checkbox completeCheck">
                  <svg
                    width="14"
                    height="11"
                    viewBox="0 0 14 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.709928 6.42896C0.318151 6.03822 0.318151 5.40361 0.709928 5.01287L1.44798 4.27677C1.83833 3.88747 2.47011 3.88751 2.8604 4.27686L4.67845 6.09052C5.06874 6.47987 5.70053 6.47991 6.09087 6.0906L11.14 1.05488C11.5303 0.665607 12.162 0.665607 12.5523 1.05488L13.2901 1.79067C13.6818 2.18141 13.6818 2.81603 13.2901 3.20676L6.09078 10.387C5.70047 10.7762 5.06876 10.7762 4.67845 10.387L0.709928 6.42896Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div class="subtaskTitle through">{{ subtask.title }}</div>
              </div>
              <div v-else class="subtaskDef">
                <div class="checkbox"></div>
                <div class="subtaskTitle">{{ subtask.title }}</div>
              </div>
            </div>
            <button
              class="remove-button clickable"
              @click="removeSubtask(index)"
            >
              Delete
            </button>
          </div>
          <div>
            <NewSubTask @create="addSubtask" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewSubTask from "@/components/taskdetails/NewSubTask.vue";

export default {
  components: { NewSubTask },
  emits: ["update"],
  props: {
    subtasks: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    const addSubtask = data => {
      emit("update", [...props.subtasks, data]);
    };

    const removeSubtask = index => {
      emit(
        "update",
        props.subtasks.filter((_, idx) => idx !== index)
      );
    };

    const toggleComplete = index => {
      emit(
        "update",
        props.subtasks.map((subtask, idx) => {
          if (idx === index) {
            return { ...subtask, complete: !subtask.complete };
          }

          return subtask;
        })
      );
    };

    return {
      addSubtask,
      removeSubtask,
      toggleComplete
    };
  }
};
</script>

<style lang="scss" scoped>
.subtaskDef {
  display: flex;
  flex-direction: row;
}
.completeCheck {
  align-items: center;
  background: $base-color-green;
  display: flex;
  justify-content: center;
}
.through {
  text-decoration: line-through;
}
.remove-button {
  @include sm-caps;
  background: $base-color;
  border-radius: $b3;
  border: none;
  color: white;
  padding: 3px 8px 4px 8px;
  &:hover {
    background-color: $base-sunrise;
    color: $base-color;
  }
}
</style>
