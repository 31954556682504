<template>
  <div class="taskTopBar">
    <div class="topBarLeft">
      <div class="taskTitle">
        <h4>{{ task.name }}</h4>
      </div>
      <div class="members">
        <AssigneesProfiles :value="task.assignees" />
      </div>
    </div>
    <div class="topBarRight">
      <div class="twoRows">
        <div class="rowTitle">Actions</div>
        <div class="actions">
          <button @click="$emit('delete')">Delete</button>
        </div>
      </div>
      <div class="twoRows">
        <div class="rowTitle">Completed</div>
        <button class="dueDate" @click="$emit('toggle-complete')">
          {{ task.completed ? "Yes" : "No" }}
        </button>
      </div>
      <div class="twoRows">
        <div class="rowTitle">Due date</div>
        <div class="dueDate" v-if="formattedDueDate">
          {{ formattedDueDate }}
        </div>
      </div>
      <div class="twoRows">
        <div class="rowTitle">Est. time</div>
        <!-- <div class="trackedTime">{{ task.estimatedTime }}</div> -->
        <div class="trackedTime">{{ task.estimatedTime }} HRS</div>
      </div>
      <div class="twoRows">
        <div class="rowTitle">Priority</div>
        <div class="trackedTime">{{ task.priority }}</div>
      </div>
      <div class="moreNav clickable"></div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import { computed } from "vue";

import AssigneesProfiles from "@/components/commons/AssigneesProfiles.vue";

export default {
  components: { AssigneesProfiles },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const formattedDueDate = computed(() =>
      format(new Date(props.task.dueDate.seconds * 1000), "MMM d")
    );

    return { formattedDueDate };
  }
};
</script>

<style></style>
