<template>
  <div class="container" v-if="task">
    <Modal show @close="goToPreviousPage">
      <div class="taskDetails">
        <div class="closeButton">
          <a @click="$router.go(-1)" class="clickable">
            <svg
              width="11"
              height="12"
              viewBox="0 0 11 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.66726 10.5962C8.25305 11.182 9.2028 11.182 9.78858 10.5962C10.3744 10.0104 10.3744 9.06066 9.78858 8.47487L7.31371 6L9.78858 3.52513C10.3744 2.93934 10.3744 1.98959 9.78858 1.40381C9.2028 0.818019 8.25305 0.81802 7.66726 1.40381L5.19239 3.87868L2.71751 1.40381C2.13173 0.81802 1.18198 0.81802 0.596194 1.40381C0.0104074 1.98959 0.0104078 2.93934 0.596194 3.52513L3.07107 6L0.596195 8.47487C0.0104081 9.06066 0.0104084 10.0104 0.596195 10.5962C1.18198 11.182 2.13173 11.182 2.71752 10.5962L5.19239 8.12132L7.66726 10.5962Z"
                fill="#2A1F60"
              />
            </svg>
          </a>
        </div>
        <TaskBar
          :task="task"
          @delete="deleteTask"
          @toggle-complete="toggleComplete"
        />
        <TaskDescription :task="task" @update-subtasks="updateSubtasks" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { inject } from "vue";
import { useRouter, useRoute } from "vue-router";

import useDocument from "@/composables/useDocument";

import Modal from "@/components/commons/Modal.vue";
import TaskBar from "@/components/taskdetails/TaskBar.vue";
import TaskDescription from "@/components/taskdetails/TaskDescription.vue";

export default {
  components: {
    Modal,
    TaskBar,
    TaskDescription
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const toast = inject("$toast");

    const goToPreviousPage = () => router.go(-1);

    const { document: task, deleteDoc, updateDoc } = useDocument(
      `companies/${route.params.companyId}/tasks`,
      route.params.taskId
    );

    const deleteTask = async () => {
      router.go(-1);
      deleteDoc();

      await toast("Successfully deleted task!", {
        type: "success"
      });
    };

    const updateSubtasks = subtasks => {
      updateDoc({ subtasks });
    };

    const toggleComplete = () => {
      updateDoc({ completed: !task.value.completed });
    };

    return {
      task,
      deleteTask,
      updateSubtasks,
      toggleComplete,
      goToPreviousPage
    };
  }
};
</script>
