<template>
  <form @submit.prevent="handleSubmit">
    <div class="checklistAdd">
      <div class="subtaskTitle">
        <input
          placeholder="Add a new checklist item..."
          class="inputSubTasks"
          type="text"
          v-model="title"
          @keypress.enter.prevent="handleSubmit"
          required
        />
        <BaseButton aria-label="create-subtask" modifiers="primary">
          Add new
        </BaseButton>
      </div>
    </div>
  </form>
</template>

<script>
import { ref } from "vue";

import BaseButton from "@/components/designsystem/BaseButton";

export default {
  components: { BaseButton },
  emits: ["create"],
  setup(_, { emit }) {
    const title = ref("");
    const completed = ref("");

    const handleSubmit = async () => {
      const subtask = {
        title: title.value,
        complete: false
      };

      emit("create", subtask);
      title.value = "";
    };

    return {
      title,
      handleSubmit,
      completed
    };
  }
};
</script>
